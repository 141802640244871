<template>
  <div class="follow">
    <div class="follow-search">
      <div>
        <van-cell-group>
          <van-field
            readonly
            name="calendar"
            :value="searchData.date"
            label="跟进时间"
            placeholder="开始时间"
            @click="showPicker = true"
            clearable
          />
          <van-field
            readonly
            name="calendar"
            :value="searchData.endtime"
            label="   "
            placeholder="结束时间"
            @click="showEndPicker = true"
            clearable
          />
        </van-cell-group>
      </div>
      <div>
        <van-field
          readonly
          :value="searchData.followPeople"
          label="跟进人员"
          placeholder="搜索跟进人员"
          @click="showPerson = true"
          clearable
        />
        <van-field
          readonly
          :value="searchData.status"
          label="状态"
          placeholder="搜索跟进状态"
          @click="showStatus = true"
          clearable
        />
      </div>
    </div>
    <div class="follow-content">
      <p v-if="followUpList.length === 0">暂无数据...</p>
      <van-list
        v-else
        v-model="pull_loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
        <div v-for="(follow, index) in followUpList" :key="index">
          <van-row>
            <van-col
              span="24"
              :class="
                follow.state === 1
                  ? 'follow-content-top'
                  : 'follow-content-tops'
              "
              style="
                display: flex;
                flex-direction: inherit;
                justify-content: space-between;
              "
            >
              <span>
                <img
                  v-if="follow.state === 1"
                  src="../../../assets/follow_list1.png"
                  alt
                />
                <img
                  v-if="follow.state === 2"
                  src="../../../assets/follow_list2.png"
                  alt
                />
                <img
                  v-if="follow.state === 3"
                  src="../../../assets/follow_list4.png"
                  alt
                />
                <img
                  v-if="follow.state === 4"
                  src="../../../assets/follow_list5.png"
                  alt
                />
                <img
                  v-if="follow.state === 5"
                  src="../../../assets/follow_list6.png"
                  alt
                />
                <img
                  v-if="follow.state === 6"
                  src="../../../assets/follow_list3.png"
                  alt
                />
                {{
                  follow.state === 1
                    ? "超时"
                    : follow.state === 2
                    ? "跟进中"
                    : follow.state === 3
                    ? "已预约已参观"
                    : follow.state === 4
                    ? "已签单"
                    : follow.state === 5
                    ? "注销"
                    : "已预约未参观"
                }}
              </span>
              <span style="margin-right: 16px">
                跟进人员：
                <span style="color: #f3973b">{{ follow.username }}</span>
              </span>
            </van-col>
            <van-col span="24">
              ID：
              <span>{{ follow.number }}</span>
              <span class="client-right">
                跟进次数：
                <span id="count-text">{{ follow.count }}</span>
              </span>
            </van-col>
            <van-divider />
            <van-col span="12">
              客户姓名：
              <span>{{ follow.name }}</span>
            </van-col>
            <van-col span="12">
              客户电话：
              <span>{{ follow.mobile }}</span>
            </van-col>
            <van-col span="24">
              客户渠道：
              <span>{{ follow.place }}</span>
            </van-col>
            <van-divider />
            <div
              class="client-botton-item"
              v-if="
                follow.state === 1 ||
                follow.state === 2 ||
                follow.state === 3 ||
                follow.state === 6
              "
            >
              <van-col
                span="12"
                v-for="(item, index) in buttonText"
                :key="index"
                style="padding: 0"
              >
                <van-button
                  round
                  size="small"
                  class="client-botton"
                  type="primary"
                  @click="getFollowInfo(item, follow.id)"
                  >{{ item }}</van-button
                >
              </van-col>
            </div>
            <div v-if="follow.state === 4">
              <div v-if="follow.orderfinishlogs">
                <van-col span="24" id="follow-up-list">
                  签单时间：
                  <span>{{ follow.orderfinishlogs.checkintime }}</span>
                </van-col>
                <van-col span="24" id="follow-up-list">
                  签单金额：
                  <span>{{ follow.orderfinishlogs.price }}元</span>
                </van-col>
                <van-col span="24" id="follow-up-list">
                  入住时间：
                  <span>{{ follow.orderfinishlogs.time }}</span>
                </van-col>
                <van-col span="24" id="follow-up-list">
                  跟进人员：
                  <span>{{ follow.username }}</span>
                </van-col>
                <van-col
                  span="24"
                  id="follow-up-list"
                  style="padding-left: 40px"
                >
                  备注：
                  <span>{{
                    follow.orderfinishlogs.desc === null ||
                    follow.orderfinishlogs.desc === ""
                      ? "无"
                      : follow.orderfinishlogs.desc
                  }}</span>
                </van-col>
              </div>
            </div>
            <div v-if="follow.state === 5">
              <div v-if="follow.orderdeletelogs">
                <van-col span="24" id="follow-up-list">
                  注销时间：
                  <span>{{ follow.orderdeletelogs.time }}</span>
                </van-col>
                <van-col span="24" id="follow-up-list">
                  跟进人员：
                  <span>{{ follow.username }}</span>
                </van-col>
                <van-col span="24" id="follow-up-list">
                  注销理由：
                  <span>{{ follow.orderdeletelogs.desc }}</span>
                </van-col>
              </div>
            </div>
          </van-row>
        </div>
      </van-list>
    </div>
    <!-- 日期列表 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="开始时间"
        :min-date="minDate"
        @confirm="onConfirmPicker"
        @cancel="onCancelPicker"
      />
    </van-popup>
    <van-popup v-model="showEndPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="结束时间"
        :min-date="minDate"
        @confirm="onConfirmEndPicker"
        @cancel="onCancelEndPicker"
      />
    </van-popup>
    <!-- 跟进人员列表 -->
    <van-popup v-model="showPerson" position="bottom">
      <van-picker
        show-toolbar
        :columns="personList"
        @confirm="onPersonConfirm"
        @cancel="onCancelPerson"
      />
    </van-popup>
    <!-- 跟进状态列表 -->
    <van-popup v-model="showStatus" position="bottom">
      <van-picker
        show-toolbar
        :columns="statusList"
        @confirm="onStatusConfirm"
        @cancel="onCancelStatus"
      />
    </van-popup>

    <!-- 更换跟进人 -->
    <van-popup v-model="showReplacePerson" closeable @close="closeReplaceInfo">
      <div class="follow_header">
        <div></div>
        <span>更换跟进人</span>
      </div>
      <van-form @submit="replacePerson" :show-error="false">
        <van-field
          readonly
          clickable
          name="picker"
          :value="replacePersonValue"
          placeholder="请选择跟进人"
          @click="showFollowPerson = true"
          :rules="[{ required: true, message: '请选择跟进人' }]"
          clearable
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            :loading="loading"
            loading-text="确认"
            >确认</van-button
          >
        </div>
      </van-form>
    </van-popup>

    <!-- 跟进人员列表 -->
    <van-popup v-model="showFollowPerson" position="bottom">
      <van-picker
        show-toolbar
        :columns="personList"
        @confirm="onFollowPersonConfirm"
        @cancel="showFollowPerson = false"
      />
    </van-popup>

    <!-- 跟进记录 -->
    <van-popup
      v-model="showFollewRecord"
      closeable
      id="followRecord"
      position="bottom"
      round
    >
      <div class="follow_header">
        <div></div>
        <span>跟进记录</span>
      </div>
      <div class="follow-record-list">
        <div
          class="follow-record-item"
          v-for="(item, index) in fllowUpRecords"
          :key="index"
        >
          <div class="follow-record-count">{{ index + 1 }}</div>
          <van-row>
            <van-col span="24">
              下次跟进日期：
              <span>{{ item.time }}</span>
            </van-col>
            <van-col span="24">
              本次跟进日期：
              <span>{{ item.createtime }}</span>
            </van-col>
            <van-col span="24" style="padding-left: 23px">
              跟进人员：
              <span>{{ item.name }}</span>
            </van-col>
            <van-col span="24" style="padding-left: 23px">
              跟进结果：
              <span>{{ item.desc }}</span>
            </van-col>
            <van-col
              span="24"
              style="padding-left: 23px"
              v-if="item.remarkdesc"
            >
              跟进备注：
              <span>{{ item.remarkdesc }}</span>
            </van-col>
          </van-row>
          <van-divider />
        </div>
        <div style="margin: 16px; margin-top: 40px">
          <van-button block type="info" @click="callPhone(fllowUpRecordMobile)"
            >现在跟进</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 客户签单 -->
    <van-popup
      v-model="showCustomerSign"
      closeable
      @close="closeCustomerSign"
      position="bottom"
      round
    >
      <div class="follow_header">
        <div></div>
        <span>客户签单</span>
      </div>
      <van-form
        @submit="onSubmitCustomerSign"
        label-width="70px"
        :show-error="false"
      >
        <van-field
          readonly
          clickable
          name="calendar"
          :value="customerSignForm.signDate"
          label="签单时间"
          placeholder="请选择签单时间"
          required
          :rules="[{ required: true, message: '请选择签单时间' }]"
          @click="showSignDatePicker = true"
        />
        <van-field
          v-model="customerSignForm.amount"
          name="签单金额"
          label="签单金额"
          placeholder="请输入签单金额（单位：元）"
          maxlength="6"
          required
          type="digit"
          :rules="[{ required: true, message: '请输入签单金额' }]"
        />
        <van-field
          readonly
          clickable
          name="calendar"
          :value="customerSignForm.stayDate"
          label="入住时间"
          placeholder="请选择入住时间"
          required
          :rules="[{ required: true, message: '请选择入住时间' }]"
          @click="showStayDatePicker = true"
        />
        <van-field
          v-model="customerSignForm.note"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          maxlength="30"
          placeholder="请输入备注"
          show-word-limit
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            :loading="loading"
            loading-text="确认"
            >确认</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <!-- 签单时间 -->
    <van-popup v-model="showSignDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="min_date"
        @confirm="onConfirmSignDatePicker"
        @cancel="showSignDatePicker = false"
      />
    </van-popup>
    <!-- 入住时间 -->
    <van-popup v-model="showStayDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="minDate"
        @confirm="onConfirmStayDatePicker"
        @cancel="showStayDatePicker = false"
      />
    </van-popup>

    <!-- 预约记录 -->
    <van-popup
      v-model="showReserRecord"
      closeable
      id="followRecord"
      position="bottom"
      round
    >
      <div class="follow_header">
        <div></div>
        <span>预约记录</span>
      </div>
      <div class="follow-record-list">
        <div
          class="follow-record-item"
          v-for="(item, index) in reserRecordList"
          :key="index"
          style="min-height: 250px"
        >
          <div class="follow-record-count">{{ index + 1 }}</div>
          <van-row style="padding-left: 14px">
            <van-col span="24">
              长者年龄：
              <span>{{ item.age }}</span>
            </van-col>
            <van-col span="24">
              健康状况：
              <span>{{ item.health }}</span>
            </van-col>
            <van-col span="24">
              预约人数：
              <span>{{ item.subscribecount }}</span>
            </van-col>
            <van-col span="24">
              预约时间：
              <span>{{ item.time }}</span>
            </van-col>
          </van-row>
          <van-row v-if="item.others">
            <van-col span="24" style="padding-left: 37px">
              姓名：
              <span>{{ item.others.name }}</span>
            </van-col>
            <van-col span="24" style="padding-left: 12px">
              联系电话：
              <span>{{ item.others.mobile }}</span>
            </van-col>
            <van-col span="24">
              与长者关系：
              <span>{{ item.others.relation }}</span>
            </van-col>
            <van-col span="24">
              身份证信息：
              <span>{{ item.others.idcard }}</span>
            </van-col>
          </van-row>
          <van-divider />
        </div>
      </div>
    </van-popup>

    <!-- 全局加载 -->
    <van-overlay :show="isShow">
      <van-loading type="spinner" color="#CC9756" />
    </van-overlay>

    <!-- 回顶部 -->
    <div class="backTop" v-if="backTopFlag">
      <van-icon name="back-top" size="25" @click="backTop" />
    </div>
  </div>
</template>
<script>
import {
  getSelectOptionsList,
  getFollowList,
  getFowllowUpList,
  changeFollowPerson,
  getFllowUpRecords,
  orderfinishlogs,
  getReserRecordList,
  remindFollow,
} from "@/api/api";
import moment from "moment/moment";
export default {
  data() {
    return {
      // 按钮加载
      loading: false,
      // 全局加载
      isShow: true,
      // 上拉加载
      pull_loading: false,
      finished: false,
      page: 1, //请求第几页
      pageSize: 10, //每页请求的数量
      total: 6, //总共的数据条数
      // 客户ID
      customerId: "",
      // 搜索数据
      searchData: { date: "", endtime: "", followPeople: "", status: "" },
      // 跟进人员ID
      followUpId: "",
      // 状态索引
      state: "",
      // 跟进时间
      showPicker: false,
      // 结束时间
      showEndPicker: false,
      minDate: new Date(2020, 8, 1),
      min_date: new Date(2020, 8, 10),
      currentDate: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      // 跟进人员
      showPerson: false,
      // 跟进人员列表
      personList: [],
      followPersonList: [],
      // 状态
      showStatus: false,
      // 跟进状态列表
      statusList: [],
      statusLists: [],
      // button文本
      buttonText: [
        "更换跟进人",
        "一键提醒跟进人",
        "拨打跟进人电话",
        "跟进记录",
        "客户签单",
        "预约记录",
      ],
      // 跟进人员数据
      followUpList: [],
      // 更换跟进人弹框的显示与隐藏
      showReplacePerson: false,
      // 更换跟进人得值
      replacePersonValue: "",
      // 更换跟进人
      showFollowPerson: false,
      // 跟换跟进人ID
      updateFollowUpId: "",
      // 跟进记录弹框的显示与隐藏
      showFollewRecord: false,
      // 跟进记录信息
      fllowUpRecords: [],
      // 跟进记录电话
      fllowUpRecordMobile: "",
      // 客户签单弹框的显示与隐藏
      showCustomerSign: false,
      // 客户签单表单数据
      customerSignForm: {
        signDate: "",
        amount: "",
        stayDate: "",
        note: "",
      },
      // 签单时间控件
      showSignDatePicker: false,
      // 入住时间控件
      showStayDatePicker: false,
      // 预约记录弹框的显示与隐藏
      showReserRecord: false,
      // 预约记录
      reserRecordList: [],
      // 回顶部的显示与隐藏
      backTopFlag: false,
    };
  },
  created() {
    // 下拉列表选择
    this.getSelectOptionsList();
    // 获取跟进人员列表
    this.getFollowList();
    // 获取查询跟进列表
    this.getFowllowUpList();
  },
  methods: {
    // 下拉列表选择
    getSelectOptionsList() {
      getSelectOptionsList().then((res) => {
        if (res.code === 200) {
          this.statusList = res.data.orderstates;
          // res.data.states.map((item) => {
          //   this.statusList.push(item.name);
          //   this.statusLists.push({ id: item.id, name: item.name });
          // });
        }
      });
    },
    // 获取查询跟进列表
    getFollowList() {
      getFollowList().then((res) => {
        if (res.code === 200) {
          res.data.map((item) => {
            this.personList.push(item.name);
            this.followPersonList.push({ id: item.id, name: item.name });
          });
        }
      });
    },
    // 获取跟进人员列表
    getFowllowUpList() {
      // 获取跟进人员列表
      getFowllowUpList({
        id: this.followUpId,
        state: this.state,
        time: this.searchData.date,
        endtime: this.searchData.endtime,
        page: this.page,
      })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.length === 0) {
              this.isShow = false;
            }
            // 加载状态结束
            this.pull_loading = false;
            if (this.followUpList.length >= res.meta.total) {
              this.finished = true;
              return;
            } else {
              this.followUpList = this.followUpList.concat(res.data);
              this.page++;
            }
            this.isShow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 加载更多
    onLoad() {
      this.getFowllowUpList();
    },
    getFollowLists() {
      this.page = 1;
      this.pull_loading = false;
      this.finished = false;
      this.followUpList = [];
      this.getFowllowUpList();
    },
    // 日期选择
    onConfirmPicker(time) {
      this.searchData.date = moment(time).format("YYYY-MM-DD");
      this.showPicker = false;
    },
    // 结束时间
    onConfirmEndPicker(time) {
      if (this.searchData.date === "") {
        this.$toast("请先选择开始时间");
      } else {
        this.searchData.endtime = moment(time).format("YYYY-MM-DD");
        this.showEndPicker = false;
        this.getFollowLists();
      }
    },
    // 日期取消
    onCancelPicker() {
      this.showPicker = false;
      this.searchData.date = this.$options.data().searchData.date;
      this.getFollowLists();
    },
    onCancelEndPicker() {
      this.showEndPicker = false;
      this.searchData.date = this.$options.data().searchData.date;
      this.searchData.endtime = this.$options.data().searchData.endtime;
      this.getFollowLists();
    },
    // 跟进人员选择搜索
    onPersonConfirm(value) {
      this.searchData.followPeople = value;
      this.followPersonList.map((item) => {
        if (this.searchData.followPeople === item.name) {
          this.followUpId = item.id;
        }
      });
      this.showPerson = false;
      this.getFollowLists();
    },
    // 跟进人员取消
    onCancelPerson() {
      this.showPerson = false;
      this.followUpId = "";
      this.searchData.followPeople = this.$options.data().searchData.followPeople;
      this.getFollowLists();
    },
    // 跟进状态选择
    onStatusConfirm(value, index) {
      this.searchData.status = value;
      this.state = index;
      // this.statusLists.map((item) => {
      //   if (this.searchData.status === item.name) {
      //     this.state = item.id;
      //     console.log(this.state);
      //   }
      // });
      this.showStatus = false;
      this.getFollowLists();
    },
    // 跟进状态取消
    onCancelStatus() {
      this.showStatus = false;
      this.state = "";
      this.searchData.status = this.$options.data().searchData.status;
      this.getFollowLists();
    },
    // 操作按钮点击效果
    getFollowInfo(text, id) {
      this.customerId = id;
      if (text === "更换跟进人") {
        this.showReplacePerson = true;
      } else if (text === "一键提醒跟进人") {
        remindFollow({
          id: id,
        }).then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
          }
        });
      } else if (text === "拨打跟进人电话") {
        let phone;
        this.followUpList.map((item) => {
          if (id === item.id) {
            phone = item.usermobile;
          }
        });
        window.location.href = "tel:" + phone;
      } else if (text === "跟进记录") {
        this.followUpList.map((item) => {
          if (id === item.id) {
            this.fllowUpRecordMobile = item.mobile;
          }
        });
        getFllowUpRecords({ id: this.customerId }).then((res) => {
          if (res.code === 200) {
            this.fllowUpRecords = res.data;
            if (this.fllowUpRecords.length === 0) {
              this.$toast("暂无跟进记录");
            } else {
              this.showFollewRecord = true;
            }
          }
        });
      } else if (text === "客户签单") {
        this.showCustomerSign = true;
      } else if (text === "预约记录") {
        getReserRecordList({ id: id }).then((res) => {
          if (res.code === 200) {
            this.reserRecordList = res.data;
            if (this.reserRecordList.length === 0) {
              this.$toast("暂无预约记录");
            } else {
              this.showReserRecord = true;
            }
          }
        });
      }
    },
    // 更换跟进人
    replacePerson() {
      this.loading = true;
      changeFollowPerson({
        id: this.updateFollowUpId,
        orderid: this.customerId,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.loading = false;
            this.showReplacePerson = false;
            this.getFollowLists();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 关闭更换跟进人弹窗清空表单数据
    closeReplaceInfo() {
      this.replacePersonValue = this.$options.data().replacePersonValue;
    },
    //跟进人得选择
    onFollowPersonConfirm(value) {
      this.replacePersonValue = value;
      this.followPersonList.map((item) => {
        if (this.replacePersonValue === item.name) {
          this.updateFollowUpId = item.id;
        }
      });
      this.showFollowPerson = false;
    },
    // 跟进记录 拨打电话
    callPhone(phone) {
      window.location.href = "tel:" + phone;
    },

    // 关闭客户签单弹窗清空表单数据
    closeCustomerSign() {
      this.customerSignForm = this.$options.data().customerSignForm;
    },
    // 客户签单签单时间
    onConfirmSignDatePicker(time) {
      this.customerSignForm.signDate = moment(time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.showSignDatePicker = false;
    },
    // 客户签单入住时间
    onConfirmStayDatePicker(time) {
      this.customerSignForm.stayDate = moment(time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.showStayDatePicker = false;
    },
    // 客户签单
    onSubmitCustomerSign() {
      this.loading = true;
      orderfinishlogs({
        id: this.customerId,
        time: this.customerSignForm.signDate,
        price: this.customerSignForm.amount,
        checktime: this.customerSignForm.stayDate,
        desc: this.customerSignForm.note,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.loading = false;
            this.showCustomerSign = false;
            this.getFollowLists();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      let timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        if (this.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      if (this.scrollTop > 60) {
        this.backTopFlag = true;
      } else {
        this.backTopFlag = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang="scss" scoped>
.follow {
  margin-top: 22px;
  .follow-search {
    min-height: 112px;
    background-color: #fff;
    div {
      min-height: 22px;
    }
    /deep/.van-field__control {
      font-size: 14px;
    }
  }
  .follow-content {
    margin-top: 10px;
  }
  .van-tabs {
    margin-top: 10px;
  }
  p {
    font-size: 14px;
    text-align: center;
    margin: 20px 0;
    color: #9a9a9a;
  }
  /deep/.van-tab--active {
    color: #f3973b;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
  }
  /deep/.van-tabs__line {
    width: 50%;
    height: 4px;
    background-color: #f3973b;
  }
  .van-row:nth-child(1) {
    min-width: 100%;
    min-height: 115px;
    background-color: #fff;
    margin-bottom: 5px;
    font-size: 13px;
    .van-col:nth-child(1),
    .van-col:nth-child(2) {
      padding: 15px 0 10px 16px;
      color: #9a9a9a;
      font-size: 12px;
    }
    .client-right {
      float: right;
      margin-right: 16px;
    }
    .van-divider {
      border-color: #eaeaea;
      padding: 0 16px;
    }
    .van-col:last-child {
      padding-bottom: 15px;
    }
    .van-col {
      font-size: 12px;
      color: #9a9a9a;
      padding-bottom: 10px;
      padding-left: 16px;
      span {
        color: #000;
        font-size: 12px;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
    }
  }
  #follow-up-list {
    padding-top: 0px;
  }
  .client-botton-item {
    min-height: 110px;
    background-color: #fff;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .van-col:nth-child(1) {
      text-align: center;
    }
    .van-col {
      font-size: 14px;
      display: flex;
      color: #f3973b;
      flex-direction: inherit;
      justify-content: center;
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
    .client-botton {
      width: 158px;
      background-color: #f2f2f2;
      color: #343434;
      border: #343434;
      font-size: 12px;
      margin-bottom: 10px;
    }
    .client-right {
      float: right;
      margin-right: 16px;
    }
  }
}
#count-text {
  color: #f3973b;
}
.follow-content-tops {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  img {
    margin-top: -3px;
  }
}
.follow-content-top {
  background-color: #ffeae6;
  display: flex;
  img {
    margin-top: -3px;
  }
}
.van-popup--center {
  border-radius: 10px;
  top: 50%;
  left: 5%;
  right: 5%;
  transform: translate3d(0, -50%, 0);
}
// 弹框
.van-popup {
  background: #fff;
  .follow_header {
    width: 100%;
    min-height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: flex-start;
    flex-direction: inherit;
    #followRecord {
      height: 345px;
      position: fixed;
      top: 0;
      background: #fff;
    }
    div {
      width: 4px;
      height: 20px;
      background: #f3983c;
      margin: 12px 8px 10px 12px;
    }
    span {
      font-size: 18px;
      color: #343434;
    }
  }
  // 更换跟进人
  .van-form {
    max-height: 400px;
    overflow: auto;
    .customer-info {
      display: flex;
      flex-direction: initial;
      justify-content: flex-start;
      color: #9a9a9a;
      span {
        flex: 1;
        padding: 16px 0 6px 21px;
      }
      span:nth-child(2) {
        color: #000;
        padding-left: 0;
      }
    }
    .van-field {
      margin-top: 10px;
    }
    .stayin-reson-botton {
      background-color: #fff;
      border: 1px solid #eaeaea;
      color: #9a9a9a;
      margin: 8px;
    }
    .stayin-reson-active {
      background-color: #fff;
      border: 1px solid #f3973b;
      color: #f3973b;
      margin: 8px;
    }
    /deep/.van-button--info,
    /deep/.van-button--primary {
      background-color: #f3973b;
      border: #f3973b;
    }
    .oneself-class {
      .van-button {
        color: #999999;
      }
    }
    .oneself-class-active {
      .van-button {
        color: #f3973b;
        background: #fef5eb;
      }
    }
  }
  // 跟进记录
  .follow-record-list {
    max-height: 400px;
    overflow: auto;
    .follow-record-item {
      padding: 16px 16px 0 16px;
      .follow-record-count {
        width: 34px;
        height: 20px;
        line-height: 24px;
        border: 1px solid #f3983c;
        border-radius: 20px;
        text-align: center;
        color: #f3983c;
        font-size: 11px;
      }
      .van-row {
        margin-top: 10px;
        .van-col {
          color: #9a9a9a;
          font-size: 12px;
          line-height: 26px;
          span {
            color: #343434;
            font-size: 12px;
          }
        }
      }
      .van-divider {
        margin-bottom: 0;
      }
    }
    .van-button {
      background-color: #f3973b;
      border: #f3973b;
    }
  }
}
/*浮窗返回顶部*/
.backTop {
  width: 35px;
  height: 35px;
  text-align: center;
  position: fixed;
  right: 10px;
  bottom: 50px;
  cursor: pointer;
  background: #f3973b;
  color: #fff;
  border-radius: 50%;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.van-overlay {
  z-index: 999;
}
</style>